<template>
  <span
    class="inline-flex items-center gap-x-1.5 rounded-full bg-lime-100 px-1.5 py-0.5 text-xs font-medium text-green-500"
  >
    <svg
      class="h-1.5 w-1.5 fill-green-500"
      viewBox="0 0 6 6"
      aria-hidden="true"
    >
      <circle cx="3" cy="3" r="3" />
    </svg>
    <slot />
  </span>
</template>
