<template>
  <div
    class="flex w-full flex-1 flex-col bg-white md:rounded-r-lg max-h-screen md:overflow-hidden h-screen min-h-screen"
  >
    <div
      class="flex justify-end py-3 px-3 md:py-0 md:pt-3 bg-rose-100 md:bg-white"
    >
      <LoginButton class="md:hidden" />
      <button
        type="button"
        class="focus:outline-none focus:ring-0 ml-auto bg-white px-2 md:px-0 rounded-md"
        @click="cerrarMenu"
      >
        <span class="sr-only">Close sidebar</span>
        <XMarkIcon class="h-6 w-6" />
      </button>
    </div>
    <InformacionDespacho class="md:hidden" />
    <Transition
      mode="out-in"
      enter-active-class="duration-100 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-if="categoriaActiva" class="px-4 h-full pt-4">
        <div class="flex items-center mb-4">
          <ArrowLongLeftIcon
            class="h-5 w-5 mr-2"
            aria-hidden="true"
            @click="categoriaActiva = undefined"
          />
          <span class="text-base leading-6 font-medium">{{
            categoriaActiva.nombre
          }}</span>
        </div>
        <div class="overflow-y-auto h-full">
          <a
            v-for="subcategoria in categoriaActiva.hijos"
            :key="subcategoria.id"
            :href="`/categoria/${categoriaActiva.ruta}?subcategorias=${subcategoria.nombre}`"
            class="block text-sm leading-8 font-normal mb-2 last:mb-60"
          >
            {{ subcategoria.nombre }}
          </a>
        </div>
      </div>

      <div v-else class="overflow-y-auto pb-24 md:pb-0 pt-4">
        <nav class="flex flex-col gap-3 pl-5 border-b-2 pb-6 border-gray-50">
          <span class="font-bold text-lg">Categorías</span>
          <a
            class="text-red-500 hover:text-red-400 transition-color font-medium flex items-center justify-between pr-5"
            href="/ofertas"
          >
            <div class="flex items-center justify-between">
              <FireIcon
                class="h-6 w-6 rounded text-white bg-red-500 p-1 mr-4 transition-colors cursor-pointer"
              />
              OFERTAS
            </div>
            <DotBadge> Nuevo </DotBadge>
          </a>
          <div
            v-for="categoria in categorias"
            :href="'/categoria/' + categoria.ruta"
            class="flex items-center justify-between pr-5"
            @mouseover="mouseOverCategoria(categoria)"
            @mouseleave="mouseLeaveCategoria(categoria)"
          >
            <a
              class="flex items-center group rounded-md text-base font-medium transition-colors md:hover:text-yellow-500 justify-between"
              :class="{
                'w-full': categoria.hijos.length,
              }"
              :href="'/categoria/' + categoria.ruta"
              @click="onClickCategoria($event, categoria)"
            >
              <div class="inline-flex items-center">
                <div class="bg-yellow-500 p-1 rounded">
                  <CategoriaIcon
                    class="w-4 h-4"
                    v-if="categoria.ruta"
                    :ruta="categoria.ruta"
                  />
                </div>
                <span class="ml-4">{{ categoria.nombre }}</span>
              </div>
              <ChevronRightIcon
                v-if="categoria.hijos.length"
                class="h-5 w-5"
                aria-hidden="true"
              />
            </a>
          </div>
        </nav>
        <nav class="mt-4 flex flex-col gap-3 px-5">
          <span class="font-bold text-lg">Entradas</span>
          <a
            v-for="entrada in entradas"
            :key="entrada.nombre"
            :href="entrada.link"
            class="group flex items-center text-base font-medium justify-between"
          >
            <div class="inline-flex items-center">
              <component class="w-6 mr-1.5" :is="iconos[entrada.icono]" />
              <span class="ml-3 transition-colors" :class="entrada.clase">{{
                entrada.nombre
              }}</span>
            </div>
            <DotBadge v-if="entrada.nuevo"> Nuevo </DotBadge>
          </a>
        </nav>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
  import type { Categoria, Entrada } from "@lib/interfaces";
  import { ref } from "@vue/runtime-core";
  import CategoriaIcon from "@components/iconos/CategoriaIcon.vue";
  import { ChevronRightIcon } from "@heroicons/vue/20/solid";
  import { FireIcon } from "@heroicons/vue/24/outline";
  import {
    BuildingOfficeIcon,
    EnvelopeIcon,
    BuildingStorefrontIcon,
    QuestionMarkCircleIcon,
    TruckIcon,
    NewspaperIcon,
    XMarkIcon,
    ArrowLongLeftIcon,
  } from "@heroicons/vue/24/outline";
  import DotBadge from "@components/ui/DotBadge.vue";
  import FrestarIcon from "@components/iconos/FrestarIcon.vue";
  import FrestClubIcon from "@components/iconos/FrestClubIcon.vue";
  import { setearCategoriaHover, resetearCategoriaHover } from "@stores/app";
  import { cerrarMenu } from "@stores/app";
  import InformacionDespacho from "./header/InformacionDespacho.vue";
  import LoginButton from "./header/LoginButton.vue";

  interface Props {
    categorias: Categoria[];
    entradas: Entrada[];
  }
  defineProps<Props>();

  const categoriaActiva = ref<Categoria>();

  const iconos = [
    BuildingOfficeIcon,
    EnvelopeIcon,
    BuildingStorefrontIcon,
    QuestionMarkCircleIcon,
    TruckIcon,
    NewspaperIcon,
    FrestarIcon,
    FrestClubIcon,
  ];
  const mouseOverCategoria = (categoria: Categoria) => {
    if (window.innerWidth < 768 || categoria.hijos.length == 0) return;
    setearCategoriaHover(categoria);
  };

  const mouseLeaveCategoria = (categoria: Categoria) => {
    if (window.innerWidth < 768 || categoria.hijos.length == 0) return;
    resetearCategoriaHover();
  };
  const onClickCategoria = ($event: MouseEvent, categoria: Categoria) => {
    if (window.innerWidth >= 768 || categoria.hijos.length == 0) return;
    $event.preventDefault();
    categoriaActiva.value = categoria;
  };
</script>
