<template>
  <TransitionRoot as="template" :show="$menuVisible">
    <Dialog as="div" class="relative z-30" @close="cerrarMenu">
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900 bg-opacity-80" />
      </TransitionChild>

      <div class="fixed inset-0 z-30 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <DialogPanel
            class="flex w-full md:max-w-sm flex-1 flex-col bg-white md:rounded-r-lg relative"
          >
            <TransitionChild
              as="div"
              class="z-40 h-full shadow-lg bg-white md:rounded-r-lg"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <Menu :categorias="categoriasComputadas" :entradas="entradas" />
            </TransitionChild>
            <PopoverSubcategorias class="absolute left-80" />
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script setup lang="ts">
  import { computed } from "@vue/runtime-core";
  import { menuVisible, cerrarMenu } from "@stores/app";
  import { user } from "@stores/user";
  import { useStore } from "@nanostores/vue";
  import type { Categoria, Entrada } from "@lib/interfaces";
  import {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  } from "@headlessui/vue";
  import Menu from "../Menu.vue";
  import PopoverSubcategorias from "../PopoverSubcategorias.vue";

  interface Props {
    categorias: Categoria[];
    entradas: Entrada[];
  }
  const props = defineProps<Props>();

  const $menuVisible = useStore(menuVisible);
  const $user = useStore(user);

  const categoriasComputadas = computed(() => {
    if (!$user.value || !$user.value.permitir_mayorista) {
      return props.categorias.filter((categoria) => categoria.id !== 13);
    }
    return props.categorias;
  });
</script>
