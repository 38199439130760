<template>
  <Transition
    enter-active-class="duration-100 ease-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-100 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="$categoriaHover"
      class="h-screen w-screen max-w-md pl-24 bg-white px-4 rounded-r-lg pt-8 pb-12"
      @mouseover="mouseOverCategoria()"
      @mouseleave="mouseLeaveCategoria()"
    >
      <div class="relative h-full">
        <p class="text-lg leading-7 font-bold mb-4">
          {{ $categoriaHover.nombre }}
        </p>
        <div class="overflow-y-auto h-full flex flex-col gap-1">
          <a
            v-for="subcategoria in $categoriaHover.hijos"
            :key="subcategoria.id"
            :href="`/categoria/${$categoriaHover.ruta}?subcategorias=${subcategoria.nombre}`"
            class="text-sm leading-8 hover:text-yellow-500 transition-colors"
          >
            {{ subcategoria.nombre }}
          </a>
        </div>
        <a
          class="absolute -bottom-8 right-0 flex items-center text-green-500 transition-colors hover:text-green-700"
          :href="'/categoria/' + $categoriaHover.ruta"
        >
          <span class="font-bold underline underline-offset-2">
            Ver todos
          </span>
          <ArrowLongRightIcon class="w-5 h-5 ml-3" />
        </a>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
  import { categoriaHover } from "@stores/app";
  import { useStore } from "@nanostores/vue";
  import { setearCategoriaHover, resetearCategoriaHover } from "@stores/app";
  import { ArrowLongRightIcon } from "@heroicons/vue/24/outline";

  const $categoriaHover = useStore(categoriaHover);

  const mouseOverCategoria = () => {
    if (window.innerWidth < 768 || !$categoriaHover.value) return;
    setearCategoriaHover($categoriaHover.value);
  };

  const mouseLeaveCategoria = () => {
    if (window.innerWidth < 768 || !$categoriaHover.value) return;
    resetearCategoriaHover();
  };
</script>
